import * as React from "react";

interface LogoProps extends React.SVGProps<any> {
  fillText?: string;
}

export const Logo = ({ fillText = "#0C1431", ...svgProps }: LogoProps) => {
  return (
    <svg width={118} height={23} viewBox="0 0 118 23" fill="none" {...svgProps}>
      <path
        d="M12.447 0C5.574 0 0 5.574 0 12.447v8.89h3.556v-8.89c0-4.908 3.983-8.89 8.89-8.89 4.909 0 8.891 3.982 8.891 8.89v8.89h3.557v-8.89C24.894 5.574 19.319 0 12.447 0z"
        fill="#4867FF"
      />
      <path
        d="M17.781 21.337v-2.845H7.112v2.845h10.67zM17.781 15.648v-2.845H7.112v2.845h10.67zM7.726 9.957h9.433a5.324 5.324 0 00-4.712-2.845 5.349 5.349 0 00-4.721 2.845z"
        fill="#4867FF"
      />
      <path
        d="M39.981 18.644c2.623.089 5.166-1.103 6.748-3.165l.018-.027-2.472-1.965-.017.027c-1.005 1.351-2.41 2.036-4.072 1.973a4.889 4.889 0 01-3.557-1.662c-.88-1.005-1.333-2.338-1.289-3.76.053-1.414.605-2.704 1.556-3.637.97-.942 2.267-1.44 3.672-1.387 1.636.053 2.978.827 3.903 2.232l.018.026 2.605-1.769-.018-.027c-1.45-2.213-3.832-3.538-6.517-3.627-.098 0-.196-.009-.293-.009a8.112 8.112 0 00-5.646 2.258c-1.61 1.52-2.534 3.592-2.614 5.824-.08 2.258.703 4.4 2.196 6.036a8.153 8.153 0 005.78 2.659z"
        fill={fillText}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.297 7.637c-1.218-1.12-2.854-1.742-4.597-1.742-1.742 0-3.378.622-4.596 1.742-1.28 1.174-1.983 2.827-1.983 4.65 0 1.813.703 3.45 1.983 4.623 1.218 1.12 2.854 1.742 4.596 1.742 1.743 0 3.379-.613 4.597-1.742 1.28-1.174 1.983-2.818 1.983-4.623 0-1.823-.703-3.476-1.983-4.65zm-4.597 8.02c-1.876 0-3.29-1.45-3.29-3.37 0-1.938 1.415-3.405 3.29-3.405 1.876 0 3.29 1.458 3.29 3.405 0 1.92-1.414 3.37-3.29 3.37zM95.885 18.235a5.866 5.866 0 002.152.418 5.852 5.852 0 004.169-1.707c1.192-1.183 1.823-2.792 1.823-4.66 0-3.102-2.098-6.391-5.992-6.391-1.227 0-2.988.462-3.903 1.76l-.25.355V6.215H90.65v16.447h3.236v-6.099l.249.356c.4.56 1.004 1.013 1.751 1.316zm1.485-9.327c1.85 0 3.343 1.512 3.343 3.37a3.306 3.306 0 01-3.343 3.343c-1.627 0-3.37-1.28-3.37-3.343a3.34 3.34 0 013.37-3.37zM68.778 8.98c.56 0 1.102.168 1.564.488.152.098.294.223.418.365.48.507.747 1.174.747 1.876v6.695h3.236V11.69c.018-2.036-.48-3.574-1.467-4.57-.809-.818-1.938-1.235-3.351-1.235-1.2 0-2.792.4-3.619 2.036l-.266.47V6.216h-3.237v12.197h3.237v-6.259-.435a2.745 2.745 0 012.738-2.739z"
        fill={fillText}
      />
      <path
        d="M117.356 6.215h-3.236v6.49h-.009a2.744 2.744 0 01-2.729 2.578 2.79 2.79 0 01-.969-.178 2.383 2.383 0 01-1.37-1.129 2.755 2.755 0 01-.4-1.423v-.24-6.098h-3.236v6.374c-.018 2.036.48 3.574 1.467 4.57.809.818 1.938 1.236 3.352 1.236 1.182 0 2.729-.178 3.609-1.716l.16-.285c-.071 2.063-1.378 3.557-3.529 3.53-.667.018-1.111-.018-1.885-.205-.569-.133-1.129-.418-1.689-.675l-1.138 2.765c1.529.88 3.378 1.235 5.254 1.191 3.494 0 6.215-2.685 6.321-6.846v-.017l.027-9.922zM57.478 6.215V7.913l-.151-.258c-.392-.56-1.085-1.014-1.823-1.325a5.888 5.888 0 00-2.17-.418 5.854 5.854 0 00-4.169 1.707c-1.191 1.183-1.822 2.792-1.822 4.659s.63 3.476 1.822 4.659a5.87 5.87 0 004.17 1.707c1.324 0 3.13-.534 3.992-1.734l.15-.249V18.404h3.237V6.214h-3.236zM54 15.656a3.373 3.373 0 01-3.369-3.37c0-1.875 1.485-3.342 3.37-3.342 1.627 0 3.369 1.28 3.369 3.343 0 1.893-1.476 3.37-3.37 3.37z"
        fill={fillText}
      />
    </svg>
  );
};

export const LogoWhite = (props) => {
  return (
    <svg width={160} height={43.7} viewBox="0 0 118 23" fill="none" {...props}>
      <path
        d="M12.447 0C5.574 0 0 5.574 0 12.447v8.89h3.556v-8.89c0-4.908 3.983-8.89 8.89-8.89 4.909 0 8.891 3.982 8.891 8.89v8.89h3.557v-8.89C24.894 5.574 19.319 0 12.447 0z"
        fill="#fff"
      />
      <path
        d="M17.781 21.337v-2.845H7.112v2.845h10.67zM17.781 15.648v-2.845H7.112v2.845h10.67zM7.726 9.957h9.433a5.324 5.324 0 00-4.712-2.845 5.349 5.349 0 00-4.721 2.845z"
        fill="#fff"
      />
      <path
        d="M39.981 18.644c2.623.089 5.166-1.103 6.748-3.165l.018-.027-2.472-1.965-.017.027c-1.005 1.351-2.41 2.036-4.072 1.973a4.889 4.889 0 01-3.557-1.662c-.88-1.005-1.333-2.338-1.289-3.76.053-1.414.605-2.704 1.556-3.637.97-.942 2.267-1.44 3.672-1.387 1.636.053 2.978.827 3.903 2.232l.018.026 2.605-1.769-.018-.027c-1.45-2.213-3.832-3.538-6.517-3.627-.098 0-.196-.009-.293-.009a8.112 8.112 0 00-5.646 2.258c-1.61 1.52-2.534 3.592-2.614 5.824-.08 2.258.703 4.4 2.196 6.036a8.153 8.153 0 005.78 2.659z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.297 7.637c-1.218-1.12-2.854-1.742-4.597-1.742-1.742 0-3.378.622-4.596 1.742-1.28 1.174-1.983 2.827-1.983 4.65 0 1.813.703 3.45 1.983 4.623 1.218 1.12 2.854 1.742 4.596 1.742 1.743 0 3.379-.613 4.597-1.742 1.28-1.174 1.983-2.818 1.983-4.623 0-1.823-.703-3.476-1.983-4.65zm-4.597 8.02c-1.876 0-3.29-1.45-3.29-3.37 0-1.938 1.415-3.405 3.29-3.405 1.876 0 3.29 1.458 3.29 3.405 0 1.92-1.414 3.37-3.29 3.37zM95.885 18.235a5.866 5.866 0 002.152.418 5.852 5.852 0 004.169-1.707c1.192-1.183 1.823-2.792 1.823-4.66 0-3.102-2.098-6.391-5.992-6.391-1.227 0-2.988.462-3.903 1.76l-.25.355V6.215H90.65v16.447h3.236v-6.099l.249.356c.4.56 1.004 1.013 1.751 1.316zm1.485-9.327c1.85 0 3.343 1.512 3.343 3.37a3.306 3.306 0 01-3.343 3.343c-1.627 0-3.37-1.28-3.37-3.343a3.34 3.34 0 013.37-3.37zM68.778 8.98c.56 0 1.102.168 1.564.488.152.098.294.223.418.365.48.507.747 1.174.747 1.876v6.695h3.236V11.69c.018-2.036-.48-3.574-1.467-4.57-.809-.818-1.938-1.235-3.351-1.235-1.2 0-2.792.4-3.619 2.036l-.266.47V6.216h-3.237v12.197h3.237v-6.259-.435a2.745 2.745 0 012.738-2.739z"
        fill="#fff"
      />
      <path
        d="M117.356 6.215h-3.236v6.49h-.009a2.744 2.744 0 01-2.729 2.578 2.79 2.79 0 01-.969-.178 2.383 2.383 0 01-1.37-1.129 2.755 2.755 0 01-.4-1.423v-.24-6.098h-3.236v6.374c-.018 2.036.48 3.574 1.467 4.57.809.818 1.938 1.236 3.352 1.236 1.182 0 2.729-.178 3.609-1.716l.16-.285c-.071 2.063-1.378 3.557-3.529 3.53-.667.018-1.111-.018-1.885-.205-.569-.133-1.129-.418-1.689-.675l-1.138 2.765c1.529.88 3.378 1.235 5.254 1.191 3.494 0 6.215-2.685 6.321-6.846v-.017l.027-9.922zM57.478 6.215V7.913l-.151-.258c-.392-.56-1.085-1.014-1.823-1.325a5.888 5.888 0 00-2.17-.418 5.854 5.854 0 00-4.169 1.707c-1.191 1.183-1.822 2.792-1.822 4.659s.63 3.476 1.822 4.659a5.87 5.87 0 004.17 1.707c1.324 0 3.13-.534 3.992-1.734l.15-.249V18.404h3.237V6.214h-3.236zM54 15.656a3.373 3.373 0 01-3.369-3.37c0-1.875 1.485-3.342 3.37-3.342 1.627 0 3.369 1.28 3.369 3.343 0 1.893-1.476 3.37-3.37 3.37z"
        fill="#fff"
      />
    </svg>
  );
};

export const LogoMark = (props) => {
  return (
    <svg width={25} height={22} viewBox="0 0 25 22" fill="none" {...props}>
      <path
        d="M12.447 0C5.574 0 0 5.574 0 12.447v8.89h3.556v-8.89c0-4.908 3.983-8.89 8.89-8.89 4.909 0 8.891 3.982 8.891 8.89v8.89h3.557v-8.89C24.894 5.574 19.319 0 12.447 0z"
        fill="#4867FF"
      />
      <path
        d="M17.781 21.337v-2.845H7.112v2.845h10.67zM17.781 15.648v-2.845H7.112v2.845h10.67zM7.726 9.957h9.433a5.324 5.324 0 00-4.712-2.845 5.349 5.349 0 00-4.721 2.845z"
        fill="#4867FF"
      />
    </svg>
  );
};

export const LogoType = (props) => {
  return (
    <svg width={86} height={22} viewBox="0 0 86 22" fill="none" {...props}>
      <path
        d="M7.981 17.644c2.623.089 5.165-1.103 6.748-3.165l.018-.027-2.472-1.965-.018.027c-1.004 1.351-2.409 2.036-4.071 1.973a4.889 4.889 0 01-3.557-1.662c-.88-1.005-1.333-2.338-1.289-3.76.054-1.414.605-2.704 1.556-3.637.97-.942 2.267-1.44 3.672-1.387 1.636.053 2.978.827 3.903 2.232l.018.026 2.605-1.769-.018-.027C13.626 2.29 11.244.965 8.559.876c-.098 0-.196-.009-.293-.009A8.112 8.112 0 002.62 3.125C1.01 4.645.086 6.717.006 8.95c-.08 2.258.703 4.4 2.196 6.036a8.153 8.153 0 005.78 2.659z"
        fill="#0C1431"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.297 6.637c-1.218-1.12-2.854-1.742-4.597-1.742-1.742 0-3.378.622-4.596 1.742-1.28 1.174-1.983 2.827-1.983 4.65 0 1.813.703 3.45 1.983 4.623 1.218 1.12 2.854 1.742 4.596 1.742 1.743 0 3.379-.613 4.597-1.742 1.28-1.174 1.983-2.818 1.983-4.623 0-1.823-.703-3.476-1.983-4.65zm-4.597 8.02c-1.876 0-3.29-1.45-3.29-3.37 0-1.938 1.415-3.405 3.29-3.405 1.876 0 3.29 1.458 3.29 3.405 0 1.92-1.414 3.37-3.29 3.37zM63.885 17.235a5.866 5.866 0 002.152.418 5.854 5.854 0 004.17-1.707c1.19-1.183 1.822-2.792 1.822-4.66 0-3.102-2.098-6.391-5.992-6.391-1.227 0-2.988.462-3.903 1.76l-.25.355V5.215H58.65v16.447h3.236v-6.099l.249.356c.4.56 1.004 1.013 1.751 1.316zm1.485-9.327c1.85 0 3.343 1.512 3.343 3.37a3.306 3.306 0 01-3.343 3.343c-1.627 0-3.37-1.28-3.37-3.343a3.34 3.34 0 013.37-3.37zM36.778 7.98c.56 0 1.102.168 1.565.488.15.098.293.223.417.365.48.507.747 1.174.747 1.876v6.695h3.236V10.69c.018-2.036-.48-3.574-1.467-4.57-.809-.818-1.938-1.235-3.351-1.235-1.2 0-2.792.4-3.619 2.036l-.267.47V5.216h-3.236v12.197h3.236v-6.259-.435a2.745 2.745 0 012.739-2.739z"
        fill="#0C1431"
      />
      <path
        d="M85.356 5.215H82.12v6.49h-.009a2.744 2.744 0 01-2.73 2.578c-.328 0-.657-.062-.969-.178a2.379 2.379 0 01-1.369-1.129 2.747 2.747 0 01-.4-1.423v-.24-6.098h-3.236v6.374c-.018 2.036.48 3.574 1.467 4.57.81.818 1.938 1.236 3.352 1.236 1.182 0 2.73-.178 3.61-1.716l.16-.285c-.072 2.063-1.379 3.557-3.53 3.53-.667.018-1.111-.018-1.885-.205-.569-.133-1.13-.418-1.69-.675l-1.137 2.765c1.529.88 3.378 1.235 5.254 1.191 3.494 0 6.215-2.685 6.321-6.846v-.018l.027-9.921zM25.478 5.215V6.913l-.151-.258c-.392-.56-1.085-1.014-1.823-1.325a5.888 5.888 0 00-2.17-.418 5.854 5.854 0 00-4.169 1.707c-1.191 1.183-1.822 2.792-1.822 4.659s.63 3.476 1.822 4.659a5.87 5.87 0 004.17 1.707c1.324 0 3.13-.534 3.992-1.734l.15-.249V17.404h3.237V5.214h-3.236zM22 14.656a3.373 3.373 0 01-3.369-3.37c0-1.875 1.485-3.342 3.37-3.342 1.627 0 3.369 1.28 3.369 3.343 0 1.893-1.476 3.37-3.37 3.37z"
        fill="#0C1431"
      />
    </svg>
  );
};
