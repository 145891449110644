export interface CognitoError {
  code?: string;
  name?: string;
  message?: string;
}

export default function parseCognitoError(err: CognitoError): string {
  switch (true) {
    case err?.message === null || typeof err?.message === "undefined":
      return "Something went wrong, please contact support@canopyservicing.com.";
    case err?.message?.includes("409"):
      return "An account with the given email already exists.";
    case err.message === "Incorrect username or password.":
      return "Incorrect username or password.";
    default:
      return "Something went wrong, please contact support@canopyservicing.com.";
  }
}
