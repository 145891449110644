import * as React from "react";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition } from "@headlessui/react";
import { Card, CardBody } from "@canopyinc/aura";

export type PasswordValidationProps = {
  password: string;
  length?: number;
  testid?: string;
  show?: boolean;
};

const PasswordValidationLine = ({
  passesValidation,
  validationString,
}: {
  passesValidation: boolean;
  validationString: string;
}) => (
  <div className="flex items-center gap-2 py-1 text-xs">
    <FontAwesomeIcon className={passesValidation ? "text-emerald-500" : "text-gray-300"} icon={faCheckCircle} />
    <div className="leading-4">{validationString}</div>
  </div>
);

export const PasswordValidation = ({ password = "", length = 12, show = true }: PasswordValidationProps) => {
  const lengthValidation = password.length >= length;
  const lowercaseValidation = /(?=.*[a-z])/.test(password);
  const uppercaseValidation = /(?=.*[A-Z])/.test(password);
  const numberValidation = /[0-9]/.test(password);
  const specialValidation = /\W/.test(password);

  return (
    <Transition
      show={show}
      enter="transition duration-150 ease-out"
      enterFrom="transform opacity-0 -translate-y-12"
      enterTo="transform opacity-100 translate-y-0"
      leave="transition duration-150 ease-in"
      leaveFrom="transform opacity-100 translate-y-0"
      leaveTo="transform opacity-0 -translate-y-12"
    >
      <Card classNames={{ card: "mt-2" }}>
        <CardBody>
          <PasswordValidationLine
            passesValidation={lengthValidation}
            validationString={`Minimum ${length} characters`}
          />
          <PasswordValidationLine
            passesValidation={lowercaseValidation}
            validationString="Minimum 1 lowercase letter"
          />
          <PasswordValidationLine
            passesValidation={uppercaseValidation}
            validationString="Minimum 1 uppercase letter"
          />
          <PasswordValidationLine passesValidation={numberValidation} validationString="Minimum 1 number" />
          <PasswordValidationLine passesValidation={specialValidation} validationString="Minimum 1 special character" />
        </CardBody>
      </Card>
    </Transition>
  );
};
