import React from "react";
import { Snackbar } from "@canopyinc/aura";

import { Page } from "@/components";
import LogoMark from "@/icons/LogoMark";
import { Logo, LogoWhite } from "@/icons/Logo";

export const AuthLayout = ({ children }) => {
  return (
    <Page
      title="Canopy Servicing"
      style={{
        justifyContent: "center",
        display: "flex",
        height: "100%",
        minHeight: "100%",
        flexDirection: "column",
      }}
    >
      <div className="h-full grid grid-cols-5 2xl:grid-cols-7">
        <div
          className="bg-primary-600 hidden md:block md:col-span-2 m-4 rounded-3xl px-12 py-10"
          style={{
            backgroundImage: "url('/assets/jpg/signin-bg.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom right",
            backgroundSize: "cover",
          }}
        >
          <LogoWhite />
        </div>
        <div className="col-span-5 md:col-span-3 2xl:col-span-4">{children}</div>
      </div>
      <div className="flex justify-center">
        <Snackbar />
      </div>
    </Page>
  );
};
